var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "drawing-text-input-mission",
      attrs: { column: "", "justify-center": "", "fill-height": "" },
    },
    [
      _c(
        "v-flex",
        { staticClass: "mission-instructions", attrs: { "d-flex": "" } },
        [_c("ResizableText", { attrs: { message: _vm.instructions } })],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "drawing-text-input-mission__transition" } },
        [
          _vm.isInputVisible
            ? _c(
                "v-flex",
                { attrs: { shrink: "" } },
                [
                  _vm.isDIYGame && !_vm.isPictionary && !_vm.isHost
                    ? _c(
                        "rtb-button",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            disabled: _vm.missionCompleted || !_vm.isScribe,
                          },
                          on: { click: _vm.onSubmit },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.missionCompleted ? "SENT" : "SUBMIT") +
                              " "
                          ),
                        ]
                      )
                    : _c("TextField", {
                        staticClass: "mission-text-feild",
                        attrs: {
                          placeholder: _vm.placeholder,
                          maxTextLength: 26,
                          status: _vm.status,
                          show: "show",
                          disabled: _vm.missionCompleted,
                          buttonText: _vm.missionCompleted ? "SENT" : "SUBMIT",
                        },
                        on: { onSubmit: _vm.onSubmit },
                      }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }