<template>
  <v-layout
    column
    justify-center
    fill-height
    class="drawing-text-input-mission"
  >
    <!-- Mission question -->
    <v-flex d-flex class="mission-instructions">
      <ResizableText :message="instructions" />
    </v-flex>
    <transition name="drawing-text-input-mission__transition">
      <v-flex shrink v-if="isInputVisible">
        <rtb-button
          class="mr-2"
          v-if="isDIYGame && !isPictionary && !isHost"
          @click="onSubmit"
          :disabled="missionCompleted || !isScribe"
        >
          {{ missionCompleted ? "SENT" : "SUBMIT" }}
        </rtb-button>
        <TextField
          v-else
          class="mission-text-feild"
          :placeholder="placeholder"
          :maxTextLength="26"
          :status="status"
          :show="'show'"
          :disabled="missionCompleted"
          :buttonText="missionCompleted ? 'SENT' : 'SUBMIT'"
          @onSubmit="onSubmit"
        />
      </v-flex>
    </transition>
  </v-layout>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import { GameMixin } from "@/mixins"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import TextField from "./TextField.vue"
import Mode from "@shared/enums/Mode"
import MissionType from "@shared/enums/Mission"
import Mission from "@shared/Mission"
import { RtbButton } from "@/components/ui"
import User from "@shared/User"

export default {
  name: "DrawingTextInput",
  mixins: [GameMixin],
  components: {
    ResizableText,
    TextField,
    RtbButton
  },
  props: {
    mode: String,
    mission: Object
  },
  data() {
    return {
      lastIncorrectPlays: 0
    }
  },
  created() {
    this.$bus.$on("drawing-response", this.onDrawingResponse)
  },
  beforeDestroy() {
    this.$bus.$off("drawing-response", this.onDrawingResponse)
  },
  computed: {
    ...mapState({
      teams: state => state.chat.chats
    }),
    ...mapGetters("auth", ["isHost", "isModerator"]),
    ...mapState("drawing", ["assignedTeamId", "assignedUserId"]),
    ...mapGetters([
      "missionFail",
      "missionSuccess",
      "missionCompleted",
      "missionPlayType",
      "missionAnswers",
      "isScribe",
      "teamID",
      "team",
      "isDIYGame"
    ]),
    answerPic() {
      return this.mission?.answer?.split(",")[0]
    },
    hostAnswer() {
      return this.mission?.answer
    },
    assignedTeam() {
      return this.teams?.[this.assignedTeamId]
    },
    assignedTeamName() {
      return this.assignedTeam?.name || "Undefined"
    },
    assignedUser() {
      return this.$store.getters["GameUsers/usersOnlineArray"].find(
        ({ id }) => id === this.assignedUserId
      )
    },
    instructions() {
      if (this.isPictionary) {
        if (this.isHost) {
          if (this.assignedTeam) {
            return `${this.assignedTeamName} is drawing: "${this.hostAnswer}"`
          }

          if (this.assignedUserId) {
            return `${User.getShortenedName(this.assignedUser)} is drawing: "${
              this.hostAnswer
            }"`
          }

          if (Mission.isTeamPlay(this.mission))
            return `Select a team to draw: ${this.hostAnswer}`

          return `Select a player to draw: ${this.hostAnswer}`
        }

        if (this.assignedTeam) {
          if (this.teamID === this.assignedTeamId) {
            return `Your team is drawing: <br/> "${this.answerPic}"`
          }
          return `Guess what "${this.assignedTeamName}" is drawing`
        }

        if (this.assignedUserId) {
          if (this.user.id === this.assignedUserId) {
            return `You are drawing: <br/> "${this.answerPic}"`
          }
          return `Guess what "${User.getShortenedName(
            this.assignedUser
          )}" is drawing`
        }

        return "Waiting on assignment..."
      }

      return this.mission.instructions || ""
    },
    correct() {
      return this.mission?.answer
    },
    lineThroughClass() {
      return this.missionFail ||
        (this.missionAnswersLength > 0 && !this.missionSuccess)
        ? " line-through"
        : ""
    },
    missionAnswersLength() {
      return this.missionAnswers.length
    },
    isTeamMission() {
      return this.missionPlayType === "team"
    },
    status() {
      if (this.isPictionary) {
        if (this.gotItRight) {
          return "correct"
        }

        if (
          !this.isHost &&
          !this.missionCompleted &&
          this.isScribe &&
          this.canPlay
        ) {
          return "active" + this.lineThroughClass
        }
      }

      if (this.missionCompleted) {
        return "correct"
      }

      if (this.isScribe) {
        return "active"
      }

      return ""
    },
    isInputVisible() {
      if (this.isPictionary && (this.isAssignedTeam || this.isAssignedUser)) {
        return false
      }

      if (this.isPictionary && !this.assignedTeamId && !this.assignedUserId) {
        return false
      }

      if (this.mode === Mode.Explain) {
        return false
      }

      return true
    },
    canPlay() {
      return (
        this.mode === Mode.Play ||
        this.mode === Mode.Huddle ||
        this.mode === Mode.Social
      )
    },
    isAssignedTeam() {
      return this.user.teamID === this.assignedTeamId
    },
    isAssignedUser() {
      return this.user.id === this.assignedUserId
    },
    isPictionary() {
      return this.mission.behavior === MissionType.DrawingPictionary
    },
    isDrawing() {
      return this.mission.behavior === MissionType.DrawingEachTeam
    },
    placeholder() {
      const answer = this.missionAnswers?.[this.missionAnswersLength - 1] ?? ""

      if (this.missionCompleted && this.isPictionary) {
        return answer
      }

      if (this.isAudit || this.isSpectator) {
        return "Answer: " + this.correct
      }

      if (!this.isScribe) {
        return "Scribe Only"
      }

      if (
        this.isTeamMission &&
        this.missionAnswersLength &&
        !this.missionCompleted
      ) {
        return typeof answer === "string" ? answer : ""
      }

      if (this.missionSuccess) {
        if (!this.isPictionary) {
          return "Received!"
        }

        return "Correct!"
      }

      if (this.missionFail) {
        return answer
      }

      if (this.isScribe && this.canPlay) {
        // if scrive and ready to go
        return "Enter here..."
      }

      // scribe but not ready to go
      return "Wait..."
    }
  },
  methods: {
    async onDrawingResponse({ caption, url }) {
      if (this.isPictionary) return
      // update mixin props here???
      this.imageUrl = url
      this.hasImage = true
      this.caption = caption
      await this.checkAnswer()
      this.answer = ""
    },
    async onSubmit(data) {
      if (!this.isScribe && !this.isHost)
        return console.log("No rights for submit")

      if (this.isPictionary && this.isAssignedTeam) return

      if (this.isPictionary) {
        this.answer = data
        await this.checkAnswer()
        this.answer = ""
        return
      }

      this.answer = data
      this.$bus.$emit("drawing-submit", data)
    }
  }
}
</script>

<style lang="scss">
.drawing-text-input-mission {
  &__transition {
    &-enter-active,
    &-leave-active {
      transition: all ease-in-out 0.8s;
      position: relative;
      max-height: 100px;
    }
    &-enter,
    &-leave-to {
      opacity: 0;
      max-height: 0;
    }
  }

  .rtb-text-field__input {
    height: 40px !important;
  }
  .rtb-text-field__btn {
    min-width: 80px !important;
    font-size: 16px !important;
  }
  &__btn {
    position: absolute;
    top: 10px;
    left: 10px;
    opacity: 0.8;
    background-color: red;
  }
  .mission-instructions {
    max-height: 100%;
    height: 100%;
  }
  .mission-text-feild {
    position: relative;
    width: 90%;
    margin: auto !important;
    margin-bottom: 6px !important;
  }
}
</style>
